import React , { useState, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Btn from "../../Shared/Btn.js";
import PrivateForm from "../../Daas/Portal/Projects/TaskForms/PrivateForm.js";
import IconPicker from 'react-icons-picker'
import {ProjectContext} from "../../Projects/ProjectContext.js";
import emptyEmbedLottie from "../../Shared/Lotties/empty-embed.json";
import { Player } from "@lottiefiles/react-lottie-player";
import lottieLinked from "../../Shared/Lotties/lottie-link.json";
import ReactTooltip from 'react-tooltip';

const EmbedForm = ({closeModal, refetchData, embedData, project_id, defaultLocation}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const {fetchProjects} = useContext(ProjectContext);
  const [disabled, setDisabled] = useState(false);
  const [kind, setKind] = useState(embedData != null ? (embedData.iframe != null ? "embed" : "url") : "embed");
  const [url, setUrl] = useState(embedData != null ? embedData.url : null);
  const [urlError, setUrlError] = useState(null);
  const [directLink, setDirectLink] = useState(embedData != null ? embedData.direct_link : null);
  const [directLinkError, setDirectLinkError] = useState(null);
  const [embed, setEmbed] = useState(embedData != null ? embedData.iframe : null)
  const [title, setTitle] = useState(embedData != null ? embedData.title : "");
  const [isPrivate, setIsPrivate] = useState(embedData != null ? embedData.private : false);
  const [location, setLocation] = useState(embedData != null ? (embedData.sidenav ? "Sidenav" : "Tabs") : (defaultLocation || "Tabs"));
  const [value, setValue] = React.useState(embedData != null ? embedData.icon : "FaUsers")

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleDirectLinkChange = (e) => {
    const value = e.target.value;
    setDirectLink(value);
    
    if (value && !validateUrl(value)) {
      setDirectLinkError('Please enter a valid URL (e.g., https://example.com)');
    } else {
      setDirectLinkError(null);
    }
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setUrl(value);
    
    if (value && !validateUrl(value)) {
      setUrlError('Please enter a valid URL (e.g., https://example.com)');
    } else {
      setUrlError(null);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/embeds`, {
      project_id: (project_id || match.params.project_id),
      title: title,
      iframe: embed, 
      url: url, 
      direct_link: directLink,
      private: isPrivate,
      sidenav: location === "Sidenav" ? true : false,
      icon: value
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        fetchProjects();
        closeModal();
        history.push(`/o/${match.params.organization_id}/projects/${response.data.project.token}/embeds/${response.data.embed.token}`);
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }
  
  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/embeds/${embedData.token}`, {
      project_id: (project_id || match.params.project_id),
      title: title,
      iframe: embed, 
      url: url, 
      private: isPrivate, 
      sidenav: location === "Sidenav" ? true : false,
      icon: value, 
      direct_link: directLink
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        fetchProjects();
        closeModal();
        window.location.reload();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div >
        <div className="row" style={{height: "90vh", overflow: "auto"}}>
          <div className="col-3 embed-iframe-left">
            <div className="field">
              <h4 className="no-margin">Embed other apps</h4>
              <p className="opacity-6">Embed your favorite apps in your project. <a href="https://guides.usequeue.com/project-collaboration/embedding" target="_blank">Video guide</a>.</p>
            </div>

            {!defaultLocation && 
              <div className="field">
                <label>Location</label>
                <select className="form-control" value={location} onChange={(e) => setLocation(e.target.value)}>
                  <option value="Sidenav">Side Navigation</option>
                  <option value="Tabs">Tabs</option>
                </select>
              </div>
            }


            <div className="field">
              <label>Title</label>
              <div class="input-group display-flex">
                {location == "Sidenav" && 
                  <IconPicker value={value} onChange={(v) => setValue(v)} searchInputPlaceholder={"Type and click the eyegla"}/>
                }
                <input className="form-control" required="true" placeholder="Short title to put as a tab" value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>
            </div>

            <div className="field">
              <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
              <label>Embed type <i className="far fa-question-circle" data-tip="Some apps allow you to embed by pasting a URL and some apps have their own embed code. Reach out to support if you need help with an app."></i></label>
              <div class="btn-group mb-25" style={{display: "flex", justifyContent: "space-around"}}>
                <a onClick={() => {setKind("url"); setEmbed(null); setDirectLink(null);}} type="button" class={`width-100-percent color-1 background-hover btn btn-small ${kind == "url" ? "background-active" : "border-all color-1"}`}>
                  URL
                </a>

                <a onClick={() => {setKind("embed"); setUrl(null); setDirectLink(null);}} type="button" class={`width-100-percent color-1 background-hover btn btn-small ${kind == "embed" ? "background-active" : "border-all color-1"}`}>
                  Iframe
                </a>

                <a onClick={() => {setKind("direct_link"); setEmbed(null); setUrl(null);}} type="button" class={`width-100-percent color-1 background-hover btn btn-small ${kind == "direct_link" ? "background-active" : "border-all color-1"}`}>
                  Link
                </a>
              </div>
            </div>

            <div className="field">
              {/* URL */}
              {kind === "url" && 
                <div className="field">
                  <label>Embed URL</label>
                  <input 
                    placeholder="https://example.com" 
                    type="url" 
                    className="form-control" 
                    value={url} 
                    onChange={handleUrlChange} 
                    required="true"
                  />
                  {urlError && <div className="text-danger small mt-1">{urlError}</div>}
                </div>
              }

              {/* Embed */}
              {kind === "embed" && 
                <div className="field">
                  <label>Embed code</label>
                  <textarea class="form-control" value={embed} onChange={(e) => setEmbed(e.target.value)} required="true" rows="5" resizable></textarea>
                </div>
              }

              {kind === "direct_link" && 
                <div className="field">
                  <label>Direct link</label>
                  <input 
                    type="url" 
                    className="form-control" 
                    value={directLink} 
                    onChange={handleDirectLinkChange} 
                    required="true"
                  />
                  {directLinkError && <div className="text-danger small mt-1">{directLinkError}</div>}
                </div>
              }
            </div>

            <div className="field text-right">
              {location == "Tabs" && 
                <PrivateForm isPrivate={isPrivate} setIsPrivate={setIsPrivate}/>
              }
              <button className="btn btn-primary btn-small" disabled={disabled || (title == "")} type="submit" onClick={embedData != null ? handleUpdate : handleSubmit}>Submit</button>
            </div>
          </div>

          <div className="col-9 default-padding border-all border-radius">
            {kind === "embed" && 
              <React.Fragment> 
                <div dangerouslySetInnerHTML={{ __html: embed }} />
              </React.Fragment>
            }

            {kind === "url" && 
              <React.Fragment>
                {url != null && 
                  <iframe src={url} className="embed-iframe"></iframe>
                }
              </React.Fragment>
            }

            {kind === "direct_link"&& 
              <div className="">
                <div className="text-center large-padding display-flex justify-content-center align-items-center" style={{flexDirection: "column", height: "75vh"}}>
                  <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={lottieLinked} />
                  <p className="opacity-6">Integration opens in a new window</p>
                  <br />
                  <a className='btn btn-small background-hover color-1' target="_blank" href="https://guides.usequeue.com/project-collaboration/embedding"><i className="fal fa-play-circle mr-8"></i>Learn more</a>
                </div>
              </div>
            }

            {(url === null && embed === null && kind !== "direct_link")&& 
              <div className="">
                <div className="text-center large-padding display-flex justify-content-center align-items-center" style={{flexDirection: "column", height: "75vh"}}>
                  <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={emptyEmbedLottie} />
                  <p className="opacity-6">We'll load your embed preview here</p>
                  <br />
                  <a className='btn btn-small background-hover color-1' target="_blank" href="https://guides.usequeue.com/project-collaboration/embedding"><i className="fal fa-play-circle mr-8"></i>Learn more</a>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmbedForm;